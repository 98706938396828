import React from "react";
import styled from "styled-components";
import { Element, scroller } from "react-scroll";

import { SectionTitle } from "../../components/sectionTitle";
import { UpArrow } from "../../components/upArrow";
import AboutImgUrl from "../../assets/pictures/Verladung_2.jpg";
import { Marginer } from "../../components/marginer";

const MoreAboutContainer = styled(Element)`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1em;
`;

const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  @media screen and (max-width: 480px) {
    max-width: 100%;
    flex-direction: column-reverse;
  }
`;

const SecondSectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #000;
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;

const ThirdSectionTitle = styled.h3`
  font-size: 21px;
  font-weight: bold;
  color: #000;
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;

const FourthSectionTitle = styled.h4`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;

const SectionText = styled.p`
  font-size: 18px;
  text-align: justify;
  margin-left: 5em;
  margin-right: 5em;
  color: #000;
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;

const UpArrowContainer = styled.div`
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
`;

export function DatenschutzSection(props) {
  const scrollToUp = () => {
    scroller.scrollTo("headerdatenschutz", { smooth: true, duration: 1500 });
  };

  return (
    <MoreAboutContainer name="datenschutzSection">
      <UpArrowContainer onClick={scrollToUp}>
        <UpArrow />
      </UpArrowContainer>
      <Marginer direction="vertical" margin="4em" />
      <SectionTitle>Impressum</SectionTitle>
      <Marginer direction="vertical" margin="1em" />
      <SecondSectionTitle>Angaben gemäß § 5 TMG:</SecondSectionTitle>
      <SectionText>
        Verwertungen Industrie Anlagen GmbH
        <br />
        Robert-Bosch-Straße 1
        <br />
        35460 Staufenberg
      </SectionText>
      <SecondSectionTitle>Vertreten durch:</SecondSectionTitle>
      <SectionText>Hakan Dalkiran</SectionText>
      <SecondSectionTitle>Kontakt:</SecondSectionTitle>
      <SectionText>
        Telefon: +49 6406 83141-0
        <br />
        Telefax: +49 6406 83141-2
        <br />
        info@via-verwertungen.com
      </SectionText>
      <SecondSectionTitle>Registereintrag:</SecondSectionTitle>
      <SectionText>
        Eintragung im Handelsregister.
        <br />
        Registergericht: Amtsgericht Gießen
        <br />
        Registernummer: HRB 2600
      </SectionText>
      <SecondSectionTitle>Umsatzsteuer:</SecondSectionTitle>
      <SectionText>
        Umsatzsteuer-Identifikationsnummer gemäß §27 a
        <br />
        Umsatzsteuergesetz: DE 169071471
      </SectionText>
      <SecondSectionTitle>Haftung für Inhalte</SecondSectionTitle>
      <SectionText>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen nicht ausgewählt oder verändert haben.
      </SectionText>
      <SectionTitle>Datenschutzerklärung</SectionTitle>
      <Marginer direction="vertical" margin="1em" />
      <SecondSectionTitle>1. Datenschutz auf einen Blick</SecondSectionTitle>
      <ThirdSectionTitle>Allgemeine Hinweise</ThirdSectionTitle>
      <SectionText>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
      </SectionText>
      <ThirdSectionTitle>Datenerfassung auf unserer Website</ThirdSectionTitle>
      <FourthSectionTitle>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</FourthSectionTitle>
      <SectionText>
        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
      </SectionText>
      <FourthSectionTitle>Wie erfassen wir Ihre Daten?</FourthSectionTitle>
      <SectionText>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
        <br />
        Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
      </SectionText>
      <FourthSectionTitle>Wofür nutzen wir Ihre Daten?</FourthSectionTitle>
      <SectionText>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
      </SectionText>
      <FourthSectionTitle>Welche Rechte haben Sie bezüglich Ihrer Daten?</FourthSectionTitle>
      <SectionText>
        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      </SectionText>
      <ThirdSectionTitle>Analyse-Tools und Tools von Drittanbietern</ThirdSectionTitle>
      <SectionText>
        Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
        <br />
        Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung informieren.
      </SectionText>
      <SecondSectionTitle>2. Allgemeine Hinweise und Pflichtinformationen</SecondSectionTitle>
      <ThirdSectionTitle>Datenschutz</ThirdSectionTitle>
      <SectionText>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
        <br />
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
        <br />
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
      </SectionText>
      <ThirdSectionTitle>Hinweis zur verantwortlichen Stelle</ThirdSectionTitle>
      <SectionText>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:

        VIA Verwertungen Industrie Anlagen GmbH
        Robert-Bosch-Straße 1
        35460 Staufenberg

        Telefon: +49 6406 83141-0
        E-Mail: info@via-verwertungen.com

        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
      </SectionText>
      <ThirdSectionTitle>Widerruf Ihrer Einwilligung zur Datenverarbeitung</ThirdSectionTitle>
      <SectionText>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </SectionText>
      <ThirdSectionTitle>SSL- bzw. TLS-Verschlüsselung</ThirdSectionTitle>
      <SectionText>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        <br />
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </SectionText>
      <ThirdSectionTitle>Auskunft, Sperrung, Löschung</ThirdSectionTitle>
      <SectionText>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
      </SectionText>
      <SecondSectionTitle>3. Datenschutzbeauftragter</SecondSectionTitle>
      <ThirdSectionTitle>Gesetzlich vorgeschriebener Datenschutzbeauftragter</ThirdSectionTitle>
      <SectionText>
        Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.
        <br />
        Herr Viktor Zudsewitsch
        Robert-Bosch-Straße 1
        35460 Staufenberg
      </SectionText>
      <SecondSectionTitle>4. Datenerfassung auf unserer Website</SecondSectionTitle>
      <ThirdSectionTitle>Cookies</ThirdSectionTitle>
      <SectionText>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
        <br />  
        Die meisten der von uns verwendeten Cookies sind so genannte "Session-Cookies". Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
        <br />
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
        <br />
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.   
      </SectionText>
      <ThirdSectionTitle>Server-Log-Dateien</ThirdSectionTitle>
      <SectionText>
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>IP-Adresse</li>
        </ul>
          Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
          <br />
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
      </SectionText>
      <ThirdSectionTitle>Kontaktformular</ThirdSectionTitle>
      <SectionText>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
        <br />
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        <br />
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </SectionText>
      <SecondSectionTitle>5. Newsletter</SecondSectionTitle>
      <ThirdSectionTitle>Newsletterdaten</ThirdSectionTitle>
      <SectionText>
        Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.
        <br />
        Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen, etwa über den "Austragen"-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        <br />
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B. E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.
      </SectionText>
      <SecondSectionTitle>6. Plugins und Tools</SecondSectionTitle>
      <ThirdSectionTitle>YouTube</ThirdSectionTitle>
      <SectionText>
        Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.
        <br />
        Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
        <br />
        Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
        <br />
        Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        <br />
        Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter: https://www.google.de/intl/de/policies/privacy.
      </SectionText>
      <ThirdSectionTitle>Google Maps</ThirdSectionTitle>
      <SectionText>
        Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
        <br />
        Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
        <br />
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </SectionText>
    </MoreAboutContainer>
  );
}
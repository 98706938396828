import React from "react";
import { Element, scroller } from "react-scroll";
import styled from "styled-components";
import { SectionTitle } from "../../components/sectionTitle";

import { UpArrow } from "../../components/upArrow";
import { Marginer } from "../../components/marginer";

const AGBContainer = styled(Element)`
  width: 100%;
  min-height: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
`;

const UpArrowContainer = styled.div`
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
`;

const SecondSectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #000;
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;

const SectionText = styled.p`
  font-size: 18px;
  text-align: justify;
  margin-left: 5em;
  margin-right: 5em;
  color: #000;
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;

export function AgbSection(props) {
  const scrollToUp = () => {
    scroller.scrollTo("headerAgb", { smooth: true, duration: 1500 });
  };
  return (
    <AGBContainer name="agbSection">
      <UpArrowContainer onClick={scrollToUp}>
        <UpArrow />
      </UpArrowContainer>
      <Marginer direction="vertical" margin="2em" />
      <SectionTitle>Allgemeine Verkaufs- und Lieferbedingungen Nr.: 01/2002
      <br />der Firma Verwertungen Industrie Anlagen GmbH (nachfolgend: VIA)
      <br />(Stand: März 2002)</SectionTitle>
      <Marginer direction="vertical" margin="1em" />
      <SecondSectionTitle>I. Allgemeines</SecondSectionTitle>
      <SectionText>
        1. Für die gesamte Geschäftsbeziehung zwischen VIA und dem Käufer einschließlich der zukünftigen gelten ausschließlich diese Allgemeinen Verkaufs- und Lieferbedingungen Nr.: 01/2002. Anderen Einkaufsbedingungen oder sonstigen Allgemeinen Geschäftsbedingungen des Käufers wird hiermit widersprochen. Sie werden nicht angewendet. VIA ist berechtigt, ihre Allgemeinen Verkaufs-, und Lieferbedingungen Nr.: 01/2002 mit Wirkung für die zukünftige gesamte Geschäftsbeziehung mit dem Käufer nach einer entsprechenden Mitteilung zu ändern.
        <br />
        2. Besteht zwischen dem Käufer und VIA eine Rahmenvereinbarung, gelten diese Allgemeinen Verkaufs- und Lieferbedingungen sowohl für diese Rahmenvereinbarung als auch für den einzelnen Auftrag.
      </SectionText>
      <SecondSectionTitle>II. Vertragsschluß</SecondSectionTitle>
      <SectionText>
        1. Angebote von VIA sind freibleibend und unverbindlich. Die zu dem Angebot gehörenden Unterlagen wie Abbildungen, Zeichnungen, Gewichts- und Maßangaben sind nur Annäherungswerte, soweit sie nicht ausdrücklich als verbindlich erklärt werden. Stellt VIA dem Käufer Zeichnungen oder technische Unterlagen über den zu liefernden technischen Kaufgegenstand zur Verfügung, so bleiben diese Eigentum von VIA.
        <br />
        2. Bestellungen des Käufers sind für diesen verbindlich. Sofern von VIA keine anderweitige schriftliche Bestätigung erfolgt, gilt die Lieferung oder Rechnung als Auftragsbestätigung.
        <br />
        3. Ist der Käufer Kaufmann, ist für den Inhalt von Bestellungen und Vereinbarungen ausschließlich die schriftliche Bestätigung von VIA maßgeblich, sofern der Käufer nicht unverzüglich schriftlich widerspricht. Dies gilt insbesondere für mündliche oder telefonische Bestellungen und Vereinbarungen. Eine Mitteilung an VIA ist jedenfalls dann nicht mehr unverzüglich, wenn sie VIA nicht innerhalb von sieben Tagen zugegangen ist.
      </SectionText>
      <SecondSectionTitle>III. Liefertermin, Lieferumfang, Lieferverzug</SecondSectionTitle>
      <SectionText>
        1. Liefertermine und -fristen gelten nur als annähernd vereinbart, wenn nicht VIA eine schriftliche Zusage ausdrücklich als verbindlich abgegeben hat. Bei nicht rechtzeitiger Klarstellung aller Einzelheiten des Auftrags durch den Käufer sowie der nicht rechtzeitigen Erbringung aller Vorleistungen des Käufers verlängern sich die Liefertermine entsprechend. Liefertermine gelten mit Meldung der Versandbereitschaft als eingehalten.
        <br />
        2. VIA ist zu Teillieferungen berechtigt, soweit diese nicht das zumutbare Mindestmaß unterschreiten.
        <br />
        3. Der Käufer hat den Lieferschein zu überprüfen und zu quittieren. Etwaige Einwendungen sind VIA unverzüglich schriftlich anzuzeigen. Andernfalls gilt die quittierte Liefermenge als anerkannt.
        <br />
        4. Bei Lieferverzögerungen durch Betriebsstörungen, behördliche Maßnahmen, Ausbleiben von Zulieferungen an VIA oder höhere Gewalt verlängert sich die Lieferfrist angemessen. Höhere Gewalt liegt auch vor bei Arbeitskampfmaßnahmen einschließlich Streiks und rechtmäßigen Aussperrungen im Betrieb von VIA oder bei den Vorlieferanten von VIA. Ansprüche des Käufers auf Schadensersatz sind in diesen Fällen in den Grenzen des Abschnittes VII (Allgemeine Haftungsbeschränkung) ausgeschlossen.
        <br />
        5. Entsteht dem Käufer durch eine von VIA verschuldete Lieferverzögerung ein Schaden, kann der Käufer diesen unter Ausschluß weitergehender Ersatzansprüche in Höhe von 0,5 % für jede Woche der Verspätung, höchstens aber in Höhe von 5% des Wertes des betroffenen Teils der Gesamtlieferung ersetzt verlangen. Im Falle des Lieferverzuges kann der Käufer nach Setzung einer angemessenen Nachfrist und mit der ausdrücklichen Erklärung, daß er nach Ablauf dieser Frist die Annahme der Leistung ablehne, vom Vertrag zurücktreten, wenn die Leistung nicht innerhalb der Nachfrist erfolgt. Weitergehende Ansprüche bei Lieferverzug, insbesondere Ansprüche auf Schadensersatz, sind nach Maßgabe der Regelungen des Abschnittes VII (Allgemeine Haftungsbeschränkung) ausgeschlossen.
      </SectionText>
    </AGBContainer>
  )
}
import React, { Component } from "react";

import styled from "styled-components";
import { HeaderContact } from "./headerContact";
import ContactForm from "./contactForm";

import { Footer } from "../../components/footer";
import { Marginer } from "../../components/marginer";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export function Contact(props) {
  return (
    <PageContainer>
      <HeaderContact />
      <ContactForm />
      <Footer />
    </PageContainer>
  );
}
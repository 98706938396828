import React from "react";
import styled from "styled-components";
import { Element, scroller } from "react-scroll";

import LogoHd from "../../assets/logo/hd-logo-big.svg";
import { UpArrow } from "../../components/upArrow";
import AboutImgUrl from "../../assets/pictures/Verladung_2.jpg";
import { Marginer } from "../../components/marginer";
import { SectionTitle } from "../../components/sectionTitle";

const MoreAboutContainer = styled(Element)`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1em;
`;

const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  @media screen and (max-width: 480px) {
    max-width: 100%;
    flex-direction: column-reverse;
  }
`;

const AboutText = styled.p`
  font-size: 21px;
  color: #2f2f2f;
  font-weight: normal;
  line-height: 1.4;
`;

const AboutImg = styled.img`
  width: 600px;
  height: 400px;
  margin-left: 7em;
  @media screen and (max-width: 480px) {
    width: 370px;
    height: 290px;
    margin-left: 0;
  }
`;

const UpArrowContainer = styled.div`
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
`;

export function AboutUsContainer(props) {
  const scrollToUp = () => {
    scroller.scrollTo("headerAboutUs", { smooth: true, duration: 1500 });
  };

  return (
    <MoreAboutContainer name="aboutUsContainer">
      <Marginer direction="vertical" margin="1em" />
      <AboutContainer>
        <AboutText>
        <p>Die VIA Verwertungen GmbH wurde im Jahr 2012 gegründet und ist seit diesem Zeitpunkt für die Demontage von Maschinen und Anlagen ein verlässlicher Partner. Als erfahrenes Abbau-Unternehmen gehört daher die Entsorgung von Demontagen und Industriedemontagen zu unseren Aufgabefeldern. Die Demontage von Industrie- und weiteren technischen Anlagen wird durch uns unter hohen Sicherheitsstandards durchgeführt.  
        </p><p>
        Bei der Umsetzung von Maschinenparks stehen wir ihnen mit unserem Know how zur Verfügung. Wir Unterstützen Sie bei der Projektierung, Demontage, Transport, Maschinen.- Remontage, Anlagenmontage und Inbetriebnahme ihrer Maschinen und Anlagen. Wir helfen ihnen beim Umzug einzelner Maschinen bis hin zum Umsetzen von Produktionsstätten.
        </p>
        </AboutText>
        <AboutImg src={AboutImgUrl} />
      </AboutContainer>
    </MoreAboutContainer>
  );
}
import React from "react";
import styled from "styled-components";
import { Element, scroller } from "react-scroll";

import { UpArrow } from "../../components/upArrow";
import { SectionTitle } from "../../components/sectionTitle";
import { Marginer } from "../../components/marginer";
import { FirstCarousel } from "../../components/carousel";
import AboutImgUrl from "../../assets/pictures/Verladung_2.jpg";

const MoreAboutContainer = styled(Element)`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1em;
`;

const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  @media screen and (max-width: 480px) {
    max-width: 100%;
    flex-direction: column-reverse;
  }
`;

const AboutText = styled.p`
  font-size: 21px;
  color: #2f2f2f;
  font-weight: normal;
  line-height: 1.4;
`;

const AboutImg = styled.img`
  width: 600px;
  height: 400px;
  margin-left: 7em;
  @media screen and (max-width: 480px) {
    width: 370px;
    height: 290px;
    margin-left: 0;
  }
`;

const UpArrowContainer = styled.div`
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
`;

export function DiensteContainer(props) {
  const scrollToUp = () => {
    scroller.scrollTo("headerDienste", { smooth: true, duration: 1500 });
  };

  return (
    <MoreAboutContainer name="diensteContainer">
      <UpArrowContainer onClick={scrollToUp}>
        <UpArrow />
      </UpArrowContainer>
      <Marginer direction="vertical" margin="0.5em" />
      <SectionTitle>Verlagerung einer Messmaschine</SectionTitle>
      <AboutContainer>
        <FirstCarousel />
      </AboutContainer>
      <SectionTitle>Entsorgung</SectionTitle>
      <AboutContainer>
        <AboutText>
        Wir kümmern uns um jeden Schrott.
        <br />
        Nach der Demontage geht es oft darum wo und wie zu entsorgen ist, wir helfen Ihnen gerne dabei und übernehmen die Demontage und fachgerechte sowie sichere Entsorgung Ihrer Industrieanlagen und Maschinen.
        <br />
        Dabei stellen der Abbau und die Entsorgung ganzer Fabrikanlagen ebenso wenig ein Hindernis für uns dar wie die Entsorgung von kompletten Produktionsbereichen, Altmaschinen und Industrieanlagen.
        </AboutText>
        <AboutImg src={AboutImgUrl} />
      </AboutContainer>
      <Marginer direction="vertical" margin="3em" />
    </MoreAboutContainer>
  );
}
import React from "react";
import styled from "styled-components";
import { TopSection } from "../homepage/topSection";

import { Footer } from "../../components/footer";
import { Marginer } from "../../components/marginer";
import { DatenschutzSection } from "./datenschutzSection";
import { HeaderDatenschutz } from "./headerDatenschutz";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export function Datenschutz(props) {
  return (
    <PageContainer>
      <HeaderDatenschutz />
      <DatenschutzSection />
      <Marginer direction="vertical" margin="5em" />
      <Footer />
    </PageContainer>
  );
}
import React from "react";
import './App.css';
import { Route, Switch } from "react-router-dom";
import { AboutUs } from "./containers/aboutUs";
import { Homepage } from "./containers/homepage";
import { Contact } from "./containers/contact";
import { Dienste } from "./containers/dienstleistungen";
import { AGB } from "./containers/agb";
import { Datenschutz } from "./containers/datenschutz";



function App() {
  return (
    <Switch>
      <Route exact path="/" component={Homepage}/>
      <Route exact path="/aboutUs" component={AboutUs}/>
      <Route exact path="/dienstleistungen" component={Dienste}/>
      <Route exact path="/contact" component={Contact}/>
      <Route exact path="/agb" component={AGB}/>
      <Route exact path="/datenschutz" component={Datenschutz}/>
    </Switch>
  )
}

export default App;

import React, { Component } from "react";

import styled from "styled-components";
import { HeaderAboutUs } from "./headerAboutUs";
import { AboutUsContainer } from "./aboutUsContainer"
import { Footer } from "../../components/footer";
import { Marginer } from "../../components/marginer";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export function AboutUs(props) {
  return (
    <PageContainer>
      <HeaderAboutUs />
      <AboutUsContainer />
      <Footer />
    </PageContainer>
  );
}
import React, { Component } from "react";

import styled from "styled-components";
import { HeaderDienste } from "./headerDienste";

import { Footer } from "../../components/footer";
import { Marginer } from "../../components/marginer";
import { DiensteContainer } from "./diensteContainer";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export function Dienste(props) {
  return (
    <PageContainer>
      <HeaderDienste />
      <DiensteContainer />
      <Footer />
    </PageContainer>
  );
}
import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import { Element, scroller } from "react-scroll";

import { SectionTitle } from "../../components/sectionTitle";
import { UpArrow } from "../../components/upArrow";
import ViaImage from "../../assets/pictures/IMG_1863_3.jpg";
import { Marginer } from "../../components/marginer";
import { ButtonSubmit } from "../../components/buttonSubmit";
import { theme } from "../../theme";

const ContactFormContainer = styled(Element)`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1em;
`;

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  @media screen and (max-width: 480px) {
    max-width: 100%;
    flex-direction: column-reverse;
    margin-left: 0;
  }
`;

const ContactText = styled.p`
  font-size: 20px;
  color: #2f2f2f;
  font-weight: normal;
  line-height: 1;
  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

const ContactImg = styled.img`
  width: 550px;
  height: 400px;
  margin-left: 10em;
  @media screen and (max-width: 480px) {
    width: 370px;
    height: 290px;
    margin-left: 0;
  }
`;

const UpArrowContainer = styled.div`
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
`;

const InputBox = styled.input`
  display: flex;
  width: 150%;
  height: 2.5em;
  margin-top: 1em;
  background: linear-gradient(
    360deg,
    ${theme.primary} 0%,
    #fff 100.23%
  );
  
  border-radius: 5px;

  @media screen and (max-width: 480px) {
    margin-right: 15em;
    width: 100%;
  }
`;

const InputTextarea = styled.textarea`
  display: flex;
  width: 150%;
  height: 7.5em;
  margin-top: 1em;
  font-size: 15px;
  font-weight: bold;
  background: linear-gradient(
    360deg,
    ${theme.primary} 0%,
    #fff 100.23%
  );
  transition: all 220ms ease-in-out;
  border-radius: 5px;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      message: "",
      firma: "",
      status: "Submit"
    };   
  } 

  handleChange(event) {
    const field = event.target.id;
    if (field === "name") {
      this.setState({ name: event.target.value });
    } else if (field === "firma") {
      this.setState({ firma: event.target.value });
    } else if (field === "email") {
      this.setState({ email: event.target.value });
    } else if (field === "message") {
      this.setState({ message: event.target.value });
    } 
  }

  handleSubmit(event) {
    event.preventDefault();  
    this.setState({ status: "Sending" });  
    axios({
      method: "POST",
      url: "http://localhost:5000/contact",
      data: this.state,
    }).then((response) => {
      if (response.data.status === "sent") {
        alert("Message Sent");
        this.setState({ name: "", email: "", message: "", firma: "", status: "Submit" });
      } else if (response.data.status === "failed") {
        alert("Message Failed");
      }
    });
  }

  render() {
    const scrollToUp = () => {
      scroller.scrollTo("headerContact", { smooth: true, duration: 1500 });
    };

    let buttonText = this.state.status;
    return (    
      <ContactFormContainer name="contactForm">
        <UpArrowContainer onClick={scrollToUp}>
          <UpArrow />
        </UpArrowContainer>
        <Marginer direction="vertical" margin="2em" />
        <SectionTitle>Schreiben Sie uns, wir sind gerne für Sie da</SectionTitle>
        <ContactContainer>
          <form onSubmit={this.handleSubmit.bind(this)} method="POST">
            <div>
            <ContactText><label htmlFor="name">Name*</label></ContactText>
            <InputBox
                type="text"
                id="name"
                value={this.state.name}
                onChange={this.handleChange.bind(this)}
                required
            />
            </div>
            <div>
            <ContactText><label htmlFor="firma">Unternehmen</label></ContactText>
            <InputBox
                type="text"
                id="firma"
                value={this.state.firma}
                onChange={this.handleChange.bind(this)}
            />
            </div>
            <div>
            <ContactText><label htmlFor="email">Email*</label></ContactText>
            <InputBox
                type="email"
                id="email"
                value={this.state.email}
                onChange={this.handleChange.bind(this)}
                required
            />
            </div>
            <div>
            <ContactText><label htmlFor="message">Message*</label></ContactText>
            <InputTextarea
                id="message"
                value={this.state.message}
                onChange={this.handleChange.bind(this)}
                required
            />
            </div>
            <Marginer direction="vertical" margin="0.5em" />  
            <ButtonSubmit type="submit">{buttonText}</ButtonSubmit>
          </form>  
          <ContactImg src={ViaImage} />
          
        </ContactContainer>
        <Marginer direction="vertical" margin="2em" />  
      </ContactFormContainer>    
      
    );
}
}

export default ContactForm;
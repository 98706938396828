import React from "react";
import { Element, scroller } from "react-scroll";
import styled from "styled-components";
import { SectionTitle } from "../../components/sectionTitle";

import { UpArrowFromBottom} from "../../components/upArrowFromBottom";
import { UpArrow } from "../../components/upArrow";
import { Marginer } from "../../components/marginer";
import { OurService } from "../../components/ourService";
import ServiceImg1 from "../../assets/pictures/Verladung Porsche KS juli 2019 10_1.jpg";
import ServiceImg2 from "../../assets/pictures/Verladung Porsche KS juli 2019 17_1.jpg";
import ServiceImg3 from "../../assets/pictures/Verladung Porsche KS juli 2019 8_1.jpg";
import ServiceImg4 from "../../assets/pictures/Verladung Porsche KS juli 2019 24_1.jpg";

const ServicesContainer = styled(Element)`
  width: 100%;
  min-height: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
`;

const UpArrowContainer = styled.div`
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
`;

export function ServicesSection(props) {
  return (
    <ServicesContainer name="servicesSection">
      <Marginer direction="vertical" margin="0.5em" />
      <SectionTitle>Unsere Services</SectionTitle>
      <Marginer direction="vertical" margin="1em" />
      <OurService 
        title ="Wir über uns" 
        description="Die VIA Verwertungen GmbH wurde im Jahr 2012 gegründet und ist seit diesem Zeitpunkt für die Demontage von Maschinen und Anlagen ein verlässlicher Partner. Als erfahrenes Abbau-Unternehmen gehört die Entsorgung von Demontagen und Industriedemontagen mit zu unseren Aufgabefeldern. Die Demontage von Industrie- und weiteren technischen Anlagen wird durch uns unter hohen Sicherheitsstandards durchgeführt.  " 
        imgUrl={ServiceImg1}
      />
      <OurService
        title="Umzug/Verlagern"
        description="Planen Sie einen Betriebs- oder Industrieumzug in naher Zukunft, und wissen nicht, wie Sie Ihre Maschinen ab- und wieder aufgebaut bekommen? Die Produktionsverlagerung oder Umstrukturierung kann oftmals zum Problem werden, aber nicht für uns, denn das ist unsere Kernkompetenz. Die VIA Verwertungen hilft Ihnen dabei gerne. Von der termingerechten Anlieferung über die Einbringung, die Aufstellung und dem Anschluss bis hin zur Inbetriebnahme sind wir Ihr Ansprechpartner.

        Bei der Umsetzung von Maschinenparks stehen wir ihnen mit unserem Know how zur Verfügung. Wir Unterstützen Sie bei der Projektierung, Demontage, Transport, Maschinen.- Remontagen, Anlagenmontage und Inbetriebnahme ihrer Maschinen und Anlagen. Wir helfen ihnen beim Umzug einzelner Maschinen bis hin zum Umsetzen von Produktionsstätten."
        imgUrl={ServiceImg2}
        isReversed
      />
      <OurService 
        title ="Demontagen" 
        description="Für die Demontage und Remontage von Maschinen oder ganzer Anlagen sind wir erfahrene Spezialisten. Unsere Monteure haben allesamt langjährige Erfahrung in der Montage und Inbetriebnahme komplizierter Anlagentechnik.

        Ein Umzug von  Maschinen und Anlagen ist eine komplexe Aufgabe, welche wir mit großer Hingabe gerne angehen. Ihre Bedürfnisse werden dabei fortwährend beachtet und gemeinsam mit Ihnen werden wir einen evidenten Plan ausarbeiten und diesen auch zeitgerecht umsetzen." 
        imgUrl={ServiceImg4}
      />
      
    </ServicesContainer>
  )
}
import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";

const ButtonWrapper = styled.button`
  padding: 7px 15px;
  border-radius: 5px;
  background-color: ${theme.primary};
  color: #1f1f1f;
  font-weight: bold;
  font-size: 16px;
  outline: none;
  border: 2px solid transparent;
  transition: all 220ms ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: rgba(55, 55, 55, 0.89);
    color: ${theme.primary};
    border: 2px solid rgba(55, 55, 55, 0.89);
  }
`;

export function ButtonSubmit(props) {
  return <ButtonWrapper {...props}>{props.children}</ButtonWrapper>;
}
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
 
import { Button } from "../button";
import { Logo } from "../logo";
import { Marginer } from "../marginer";

const NavbarContainer = styled.div`
  margin: 0.3em;
  width: 100%;
  height: 25px;
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const BrandContainer = styled.div``;

const AccessibilityContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  
  @media screen and (max-width: 480px) {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const HomeButton = styled(Button)`
  font-size: 24px;
  background-color: transparent;
  border: none;
  &:hover {
    background-color: transparent;
    border: none;
    color: rgb(212, 212, 212);
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const PartnersButton = styled(Button)`
  font-size: 24px;
  background-color: transparent;
  border: none;
  &:hover {
    background-color: transparent;
    border: none;
    color: rgb(212, 212, 212);
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const UnternehmenButton = styled(Button)`
  font-size: 24px;
  background-color: transparent;
  border: none;
  &:hover {
    background-color: transparent;
    border: none;
    color: rgb(212, 212, 212);
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const KontaktButton = styled(Button)`
  font-size: 24px;
  background-color: transparent;
  border: none;
  &:hover {
    background-color: transparent;
    border: none;
    color: rgb(212, 212, 212);
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export function Navbar(props) {
  return (
    <NavbarContainer>
      <BrandContainer>
        
      </BrandContainer>
      <AccessibilityContainer>
        <Marginer direction="horizontal" margin="25%" />
        <Link to ="/"><HomeButton small>Home</HomeButton></Link>
        <Marginer direction="horizontal" margin="25%" />
        <Link to ="/dienstleistungen"><PartnersButton small>Dienstleistungen</PartnersButton></Link>
        </AccessibilityContainer><AccessibilityContainer>
        <Marginer direction="horizontal" margin="25%" />
        <Link to="/aboutUs"><UnternehmenButton small>Über uns</UnternehmenButton></Link>
        <Marginer direction="horizontal" margin="25%" />
        <Link to="/contact"><KontaktButton small>Kontakt</KontaktButton></Link>
      </AccessibilityContainer>
    </NavbarContainer>
  );
}
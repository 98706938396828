import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Marginer } from "../marginer";

const FooterContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1f1f1f;
  position: relative;
`;

const MotivationalText = styled.h1`
  font-size: 25px;
  font-weight: 500;
  line-height: 1.4;
  color: #fff;
  margin: 0;
`;

const ContactText = styled.h2`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  color: #fff;
  margin: 0;
`;

const AccessibilityContainer = styled.div`
  width: 80%;
  display: flex;
  border-top: 1px solid #cdcdcd;
  padding-top: 12px;
  padding-right: 10px;
  padding-left: 10px;
  color: #fff;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    width: 90%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const PrivacyContainer = styled.div`
  display: flex;
`;

const LinkTo = styled.a`
  color: #fff;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  &:not(:last-of-type) {
    margin-right: 11px;
    @media screen and (max-width: 480px) {
      margin-right: 9px;
    }
  }
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
  &:hover {
    color: #adadad;
  }
`;

const RightsReserved = styled.div`
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12px;
`;


export function Footer(props) {
  return (
    <FooterContainer>
      <Marginer direction="vertical" margin="4em" />
      <MotivationalText>Contact us</MotivationalText>
      <Marginer direction="vertical" margin="3em" />
      <ContactText>Robert-Bosch-Straße 1</ContactText>
      <ContactText>35460 Staufenberg</ContactText>
      <ContactText>+4964069090884</ContactText>
      <ContactText>info@via-verwertungen.com</ContactText>
      <Marginer direction="vertical" margin="10em" />
      <AccessibilityContainer>
        <PrivacyContainer>
          <Link to="agb"><LinkTo>AGB</LinkTo></Link>
          <Marginer direction="horizontal" margin="10px" />
          <Link to="datenschutz"><LinkTo>Datenschutz</LinkTo></Link>
          <Marginer direction="horizontal" margin="10px" />
          <Link to="contact"><LinkTo>Contact us</LinkTo></Link>
        </PrivacyContainer>
      </AccessibilityContainer>
      <RightsReserved>&copy; Via GmbH All rights reserved</RightsReserved>
    </FooterContainer>
  );
}
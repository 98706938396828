import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styled from "styled-components/macro";

import CarouselFoto1 from "../../assets/pictures/1.jpg";
import CarouselFoto2 from "../../assets/pictures/2.jpg";
import CarouselFoto3 from "../../assets/pictures/3.jpg";
import CarouselFoto4 from "../../assets/pictures/4.jpg";
import CarouselFoto5 from "../../assets/pictures/5.jpg";
import CarouselFoto55 from "../../assets/pictures/5,5.jpg";
import CarouselFoto6 from "../../assets/pictures/6.jpg";
import CarouselFoto7 from "../../assets/pictures/7.jpg";

const CarouselImg = styled.img`
  width: 18em;
  height: 43em;
  @media screen and (max-width: 480px) {
    width: 18em;
    height: 13em;
  }
`;


export function FirstCarousel() {
  return (
    <Carousel>
      <CarouselImg src={CarouselFoto1} />
      <CarouselImg src={CarouselFoto2} />
      <CarouselImg src={CarouselFoto3} />
      <CarouselImg src={CarouselFoto4} />
      <CarouselImg src={CarouselFoto5} />
      <CarouselImg src={CarouselFoto55} />
      <CarouselImg src={CarouselFoto6} />
      <CarouselImg src={CarouselFoto7} />
    </Carousel>
  )
}
